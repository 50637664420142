#clients {
  .client-list {
    color: @text-color-secondary;
    background-color: @text-color;
    border-radius: 2em;
    padding: 1em;
    padding-left: 0;
    .title {
      font-size: 2em;
      font-weight: 500;
      padding: 1em;
    }
    .right-items {
      align-self: center;
      padding: 1em;
    }
    .client-card {
      background-color: #c3edf388;
      margin: 1em;
      border-radius: 1em;
      .name {
        font-size: 1.2em;
        font-weight: 500;
      }
      .description {
        list-style: none;
        color: #002b42;
        margin: 0em !important;
      }
      .client-pic {
        height: 100px;
        width: 100px;
        border-radius: 20%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}

#client-profile {
  background-color: white;
  padding: 1.5rem;
  border-radius: 2em;
  .client-list {
    color: @text-color-secondary;
    background-color: @text-color;
    margin-top: 1em;
    border-radius: 1em;
    padding: 1em;
    .title {
      font-size: 2em;
      font-weight: 500;
    }
    .subtitle {
      font-size: 1.5em;
      font-weight: 500;
    }
    .right-items {
      align-self: center;
      padding: 1em;
    }
    .client-card {
      background-color: #c3edf388;
      margin: 1em;
      border-radius: 1em;
      .name {
        font-size: 1.2em;
        font-weight: 500;
      }
      .description {
        list-style: none;
        color: #002b42;
        margin: 0em !important;
      }
      .client-pic {
        height: 100px;
        width: 100px;
        border-radius: 20%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
  .emp-detImg {
    height: 66px;
    width: 66px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .emp-cont {
    display: flex;
    color: #002b42;
    p {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .ejh {
    border-radius: 50px;
    background-color: #c3edf3;
    border: 1px solid #c3edf3;
    padding: 11px;
    width: 200%;
    margin-top: 1rem;
  }
  .det-cont1 {
    display: flex;
    padding-left: 6px;
    p {
      color: black;
    }
  }
  .his-task {
    height: 300px;
    background-color: @bg-color;
    border-radius: 2em;
    padding: 2em;
  }
}

.client-company-details {
  border-radius: 2em;
  background-color: #c3edf3;
  padding: 2rem;
  width: 80%;
}

.agent-company-details {
  border-radius: 1em;
  background-color: #c3edf3;
  padding: 1rem;
  width: fit-content;
  margin-top: 1rem;
}
