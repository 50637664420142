.bill-img {
  max-height: 95vh;
  border-radius: 6px;
  height: auto;
  width: 100%;
}
.bill-image {
  height: 300px;
  width: 100%;
  margin-bottom: 15px;
}
.bill-image-un {
  height: 200px;
  width: 100%;
}
.titleWidth {
  width: 50%;
}
.bill-post {
  background-color: rgba(11, 196, 174, 0.8);
  padding: 10px;
  color: white;
}
.n-arrow {
  margin-right: 4px;
  margin-left: 10px;
}
.bMargin {
  margin-top: 10px;
}
.ant-modal-body {
  color: black !important;
}
.close-icon {
  color: red;
  font-size: 25px;
  display: flex;
  cursor: pointer;
  flex-direction: row-reverse;
}
.bill-items {
  .ant-form-item {
    margin-bottom: 0px;
  }
  input {
    border: none;
    border-bottom: 1px solid grey;
    width: 140px;
    border-radius: 4px 4px 0px 0px;
    height: 20px;
    &:disabled {
      background-color: @primary-color;
      color: white !important;
      border-radius: 3px 3px 4px 4px;
    }
  }
}
.btn-row {
  background-color: #0a0aa7;
  color: white;
  border: none;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.step2-bill {
  width: 58% !important;
  margin-left: 2rem;
}

.overlay-image{
  position: relative;
}

.overlay{
    position: absolute;
    padding: 0;
    right: 0;
    width: 45%;
    height: 7%;
    background-color: #ffffff;
    transition: opacity .5s;
    border-radius: 0px !important;
}