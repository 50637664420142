.attachment-details {
    padding-top: 32px;
    padding-right: 40px;
    margin-bottom: 15px;
    border-radius: 10px;
    width: 145%;
}

.module-section {
    margin-bottom: 15px;
    min-height: 15vh;
    border-radius: 10px;
}

#del-task {
    border: none;
    background: none;
    cursor: pointer;
}

.ant-table-tbody>tr>td {
    vertical-align: sub !important;
}


.ant-checkbox .ant-checkbox-inner {
    border-color: black;
    width: 18px;
    height: 18px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: green;
    border-color: green;
}

td.ant-table-cell,
th.ant-table-cell {
    white-space: nowrap !important;
}