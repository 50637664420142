.no-data-container {
  text-align: center;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.no-data-icon {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
}

.no-data-text {
  font-size: 18px;
  margin-top: 10px;
}
