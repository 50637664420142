#employee {
  .emp-card {
    border-radius: 1em;
    background-color: #c3edf3;
    padding: 1rem;
    display: flex;
  }
  .emp-img {
    height: 105px;
    width: 110px;
    border-radius: 11px;
  }
  .emp-cont {
    display: flex;
    color: #002b42;
    p {
      margin-bottom: 5px;
    }
  }
}

#employeeProfile {
  .em-p-box {
    background-color: white;
    border-radius: 1em;
    width: 100%;
    padding: 1em;
  }
  .emp-detImg {
    height: 66px;
    width: 66px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .emp-cont {
    display: flex;
    color: #002b42;
    p {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .ejh {
    margin-top: 0.8em;
    border-radius: 50px;
    background-color: #c3edf3;
    border: 1px solid #c3edf3;
    padding: 11px;
    width: 30%;
  }
  .det-cont1 {
    display: flex;
    padding-left: 6px;
    p {
      color: black;
    }
  }
  .his-task {
    background-color: @bg-color;
    border-radius: 2em;
    padding: 2em;
  }
}
.tasklink {
  color: black;
  font-size: 17px;
}
.tasklink:hover {
  color: blue;
}

#employee-header{
  padding-left: 2rem;
}

.emp-card-detail{
    background-color: white;
    border-radius: 2em;
    width: 100%;
    min-height: 90vh;
    padding: 1rem;
}

.header-name{
  display: flex;
}

.header-details{
  margin-top: -13px;
}

.task-status{
  margin-right: 12rem;
  padding-right: 5px;
}

.emp-details-card {
  // background-color: #C3EDF3;
  padding: 2rem;
  min-height: 40vh;
}

.assign-grid{
  display: grid;
}

.assigne-card{
  min-width: 290px;
}

.hidden{
  display: none;
  cursor: pointer;
}