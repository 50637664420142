@import "variables/colors.less";
@import "variables/size.less";
@import "variables/fonts.less";
@import "layouts/main.less";
@import "components/main.less";
@import "ant-customisation.less";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");
.flex {
  display: flex;
}

.btn-custom {
  border: none;
  background-color: #00abc5;
  color: white;
  padding: 5px 10px;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 16px;
}
.btn-custom:hover {
  border: none;
  background-color: #00abc5;
  padding: 5px 10px;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 16px;
}
.capital {
  text-transform: capitalize;
}

.content-y-padding {
  padding-top: 5em;
  padding-bottom: 2.5em;
}
.grad {
  background-image: linear-gradient(to right, @primary-color, @secondary-color);
}

.flex-container {
  display: flex;
  justify-content: space-between;
}
.primary-button {
  border: none;
  border-radius: 0.5em;
  width: fit-content;
  height: fit-content;
  padding: 0.5em 1em;
  background-color: @primary-color;
  color: @text-color-secondary;
}
.search-people {
  margin-left: 1em;
  background-color: #e1f6f9;
  color: #b1b7bd;
  border: none;
  border-radius: 1em;
  height: 2.5em;
}

.com-card {
  background-color: white;
  padding: 1rem;
}
.st-shadow {
  box-shadow: 2px 7px 25px 16px rgb(0 0 0 / 5%);
}
.st-radius {
  border-radius: 0.9em;
}
.st-input {
  border: 1px solid #c4bfbf;
  border-radius: 3px;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  color: black;
}
.st-detail {
  background-color: white;

  border-radius: 0.9em;
  padding: 1rem;
  margin-top: 1px;
  height: 97vh;
  .title {
    color: black;
    font-size: 2em;
    font-weight: 500;
  }
  .subtitle {
    color: black;

    font-size: 1.5em;
    font-weight: 500;
  }
}
.mt-16 {
  margin-top: 16px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-10 {
  margin-top: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.my-32 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn-prev {
  color: #00abc5;
  border-color: #00abc5;
}
.primary-button {
  cursor: pointer;
}

.saved-indicator {
  background-color: #006880;
  width: 25px;
  height: 30px;
  position: absolute;
  z-index: 1000;
  border-radius: 0px 0px 5px 5px;
}

@media only screen and (max-width: 750px) and (min-width: 320px) {
  .content-y-padding {
    padding-bottom: 0px;
    padding-top: 2rem;
  }
  #pricing .pricing-card {
    margin: 1rem;
    height: 38rem;
  }
  #pricing .overlap-icon {
    top: 9%;
  }
  #services {
    padding: 2rem;
  }
  #pricing .overlap-icon {
    font-size: 3em;
  }
}
.form-wrapper {
  // margin-right: 1em;
  // padding: 1em;
  height: 84vh;
  overflow: auto;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: @primary-color;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: @secondary-color;
}
