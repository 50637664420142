.ant-btn-dashed {
  color: black;
}

.ant-form {
  color: black;
}

.ant-dropdown-menu-item {
  color: black;
}

.ant-picker-dropdown {
  background-color: white;
}

.ant-picker-cell-in-view {
  color: black;
}

.ant-picker {
  border-radius: 0.5em;
}

.ant-picker-input > input {
  color: black;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
input.ant-input {
  color: black !important;
  border-radius: 0.5em;
}

.ant-modal-close {
  color: #000;
}

.ant-select-selection-placeholder {
  color: #bbb !important;
}

.ant-select-selector {
  border-radius: 0.5em !important;
}

.ant-select-dropdown {
  background-color: white !important;

  .ant-select-item-option-content {
    color: black;
  }
}

.ant-btn-primary {
  border-radius: 0.5em;
}

.ant-modal {
  padding-bottom: 0 !important;

  .ant-modal-header {
    border-radius: 1em 1em 0em 0em;
    background-color: @primary-color;

    .ant-modal-title {
      font-weight: bold;
      color: white;
    }
  }
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  background-color: @primary-color;
  padding: 0.5em 1.7em;
  border-radius: 5px;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  color: @primary-color;
  background-color: #fff;
  padding: 0.5em 1.7em;
  border-radius: 5px;
  border: 1px solid @primary-color;
}

.ant-table {
  line-height: 1px;
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-thead .ant-table-cell {
    background-color: @primary-color;
    color: @text-color-secondary;
    font-weight: 600;
  }
}
