#chat{

    .ant-layout-sider-children{
        background:white;
    }
    .ant-menu-item{
        color: black;
    }
    .chat-img{
        height: 60px;
        width: 60px;
        border-radius: 50%;
      }
      .site-layout{
        background-color: #F2F6FC;
      }
}