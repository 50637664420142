#company-profile {
  .company-col {
    padding: 1em;
  }

  .white-box {
    background-color: white;
    border-radius: 2em;
    width: 100%;
    padding: 2em;

    .top-head {
      font-size: 1.5em;
      font-weight: 500;
    }
  }

  ul {
    li {
      color: black;
      list-style-type: none;
      font-size: large;

      .title {
        padding: 0.5em;
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  ol {
    li {
      color: black;
      font-size: large;
    }
  }
}

#company-header {
  padding-left: 2rem;
}

.comps-gal {
  border: none;
  background: none;
  cursor: pointer;
}

.ongoing-tasks {
  background-color: #FFFFFF !important;
  box-shadow: 10px black !important;
}

.addbillmodal .ant-modal-content,
.addbillmodal .ant-modal-header {
  border-radius: 1em !important;
}