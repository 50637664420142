.banner-text {
  color: @text-color-secondary;
  padding-left: 4em;
  padding-right: 4em;
  .text-big {
    font-weight: bold;
    font-size: 4em;
    line-height: 120%;
  }
  .short-desc {
    font-size: 1.5em;
    .text-bold {
      font-weight: bold;
    }
  }
  .start-button {
    float: right;
    font-size: 1.5em;
    padding: 0.5em 2em;
    margin: 2em 0em;
    border-radius: 2em;
    background-color: @text-color-secondary;
    color: @primary-color;
  }
}
.pic-wrapper {
  float: right;
  position: relative;
  .dash-pic {
    position: relative;
    float: right;
    width: 80%;
  }
  .mobile-pic {
    position: absolute;
    top: 110px;
    left: 60px;
    width: 20%;
  }
}
.about-col1 {
  padding-left: 8em;
}
.about-col2 {
  padding-right: 8em;
}
.about-heading {
  color: #002b42;
  font-size: 4em;
  font-weight: 500;
}
.about-text {
  color: #006880;
}

#nav {
  position: sticky;
  top: 0;
  z-index: 10;
  color: @text-color-secondary;
  .menuBar {
    padding: 1em 1em 0em 1em;
    overflow: auto;
    box-shadow: 0 1px 5px #11111188;
  }
  .fiscall {
    font-weight: bold;
    font-size: 2em;
  }
  .finance {
    font-weight: bold;
    font-size: 1em;
  }
  .oval-button {
    border-radius: 1em;
    margin-top: 0.75em;
    margin-left: 8em;
    margin-right: 2em;
    &:hover {
      background-color: @text-color-secondary;
      color: @primary-color;
    }
  }

  .menuCon {
    float: right;
    ul.nav-items {
      font-weight: 500;
      font-size: 1em;
      list-style-type: none;
      li {
        float: left;
        margin: 1em 2em;
        a.nav-link {
          color: @text-color-secondary;
          text-decoration: none;
          display: inline-block;
          padding-bottom: 0.25rem;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 2px;
            background-color: @text-color-secondary;
            transition: width 0.25s ease-out;
          }
          &:hover::before {
            width: 50%;
          }
        }
      }
    }
  }

  .logo {
    float: left;
    margin-left: 2.5rem;
  }
}

#pricing {
  .plan-name {
    font-size: 2rem;
    font-weight: 700;
    color: @text-color-secondary;
  }
  .card-row {
    align-items: flex-end;
  }
  .card-col {
    text-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5em;
    vertical-align: bottom;
  }
  .overlap-icon {
    position: absolute;
    left: 5%;
  }
  .pricing-card {
    background-color: @text-color-secondary;
    color: @text-color;
    text-align: start;
    padding: 1em;
    margin: 2em;
    border-radius: 1em;
    height: 30rem;
    .rs {
      font-weight: normal;
      font-size: 1.5em;
    }
    .num {
      font-weight: normal;
      font-size: 2em;
    }
    .month {
      font-weight: normal;
      color: #a5adbb;
    }
    .description {
      font-weight: normal;
      color: #a5adbb;
    }
    .title {
      font-size: 1.5em;
    }
    .features {
      font-weight: normal;
      color: #6e798c;
      padding: 0.2em;
    }
    button {
      align-self: center;
      background-color: #ebeced;
      color: #798289;
      border: none;
      border-radius: 0.5em;
      width: 100%;
      padding: 0.5em 0em;
    }
    hr {
      color: #ebeced;
    }
  }
}
#services {
  padding: 6rem 2rem 6rem 2rem;
  .owl-item.active.center .item {
    transform: scale(1);
    opacity: 1;
  }
  .ser-title {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: @primary-color;
    padding-bottom: 1rem;
  }

  .item {
    background-image: linear-gradient(
      to right,
      @primary-color,
      @secondary-color
    );
    padding: 3rem;
    opacity: 0.5;
    transform: scale(0.7);
    border-radius: 35px;
  }
  .s-tit {
    font-weight: 700;
    color: white;
  }
  .p-tit {
    font-size: 17px;
  }
  .owl-carousel {
    .owl-prev {
      color: @text-color-secondary;
      background-color: @primary-color;
      font-size: 56px;
      position: absolute;
      left: 25%;
      top: 31%;
      box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
      line-height: 50px;
      border-radius: 50px;
      border: @text-color-secondary solid 5px;
      span {
        padding: 16px;
      }
    }

    .owl-next {
      color: @text-color-secondary;
      background-color: @secondary-color;
      font-size: 56px;
      position: absolute;
      right: 25%;
      top: 31%;
      box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
      line-height: 50px;
      border-radius: 50px;
      border: @text-color-secondary solid 5px;
      span {
        padding: 16px;
      }
    }
  }
  .services-heading {
    font-size: 4em;
    text-align: center;
    font-weight: bold;
    background: -webkit-linear-gradient(@primary-color, @secondary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
#footer {
  background-color: #324b51;
  color: @text-color-secondary;
  .vl {
    border-left: 1px solid @text-color-secondary;
    height: 200px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .copyright {
    text-align: center;
    padding-bottom: 2em;
  }
  .footer-col {
    padding: 4em;
  }
}
#footer .footer-col p {
  color: white;
}
.footer-last-text {
  padding: 3rem;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) and (min-width: 320px) {
  .footer-last-text {
    display: block;
    padding: 0rem 1rem 2rem 2rem;
  }
  .res-fapp {
    margin-top: 10px;
  }
  #pricing .pricing-card .description {
    font-size: 18px;
  }
  .picon1 {
    top: 11%;
  }
  .logo {
    margin-left: 10px !important;
  }
  #services .item {
    padding: 1rem;
  }

  #services .services-heading {
    font-size: 3em;
  }
  #services .owl-carousel .owl-next {
    right: -14%;
  }
  #services .owl-carousel .owl-prev {
    left: -15%;
  }
  #footer .footer-col {
    padding: 2rem;
  }
  #about .about-col1 {
    padding-left: 3em;
    padding-top: 5rem;
  }
  .res-abt {
    padding-left: 2rem;
    padding-right: 2rem !important;
  }
  #about .about-heading {
    font-size: 3em;
  }
  #home .banner-text {
    padding-left: 2rem;
  }
  #home .banner-text .text-big {
    font-size: 2.5em;
  }
  #home .banner-text .start-button {
    padding: 1rem;
  }
  .nav-items {
    padding: 0px;
  }
  #nav .menuCon ul.nav-items li {
    margin: 14px;
  }
  #nav .oval-button {
    margin-left: 1rem;
  }
  #login .login-content {
    padding: 2rem !important;
  }
}
