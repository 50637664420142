#search-wrapper {
  position: relative;
  #search {
    border: none;
    padding: 1em 2em 1em 2em;
    border-radius: 1em;
    width: 50%;
    color: @text-color-secondary;
  }
  .input-icon {
    position: absolute;
    top: 25%;
    left: 1em;
  }
}

.main-container {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 30px 20px;
  .main-title {
    font-size: 20px;
    font-weight: 600;
  }
  .main-subtitle {
    font-size: 18px;
    font-weight: 500;
  }
}

.main-container-N {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 10px 20px;
  height: 100vh;
  overflow: scroll;
  .main-title {
    font-size: 20px;
    font-weight: 600;
  }

  .main-subtitle {
    font-size: 18px;
    font-weight: 500;
  }
}

.content-end {
  justify-content: end;
}
.content-apart {
  justify-content: space-between;
}
.content-center {
  justify-content: center;
}
.link-element {
  cursor: pointer;
}
.shadow-card {
  box-shadow: 2px 7px 25px 15px rgb(0 0 0 / 5%);
  -webkit-box-shadow: 2px 7px 25px 15px rgb(0 0 0 / 5%);
  -moz-box-shadow: 2px 7px 25px 15px rgba(0, 0, 0, 0.05);
}
.profile-card {
  max-width: 280px;
  border-radius: 30.9px;
  background-color: #fff;
  position: relative;
  padding: 50px 20px 10px;
  margin: 60px 0px 0px;
  text-align: center;
  .profile-meta {
    font-size: 16px;
    font-weight: 200;
    align-content: center;
    align-items: center;
  }

  .card-img {
    max-width: 100px;
    width: 100%;
    height: 100px;
    position: absolute;
    top: -52px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .card-title {
    font-size: 20px;
    font-weight: 600;
  }
}

.detail-icon {
  text-align: center;
  margin-top: -40px;
}
