#login {
  color: @text-color;
  background-image: linear-gradient(@primary-color, @secondary-color);
  .login-content {
    height: 100vh;
    border-radius: 4em 0 0 4em;
    background-color: #fff;
    padding: 0 4em 0em 4em;
    .login-heading {
      font-size: 3em;
      font-weight: bold;
    }
    .or {
      text-align: center;
      color: #b5b5b5;
      padding: 1em;
    }
    input {
      width: 100%;
      margin-top: 24px;
      border: none;
      border-bottom: 1px solid #002b4288;
      outline: none;
    }
    button.login-button {
      border: none;
      border-radius: 0.5em;
      width: 100%;
      padding: 0.5em 0em;
      background-color: @primary-color;
      color: #fff;
      font-weight: 600;
      margin-top: 48px;
      margin-bottom: 24px;
      cursor: pointer;
    }
    button.social-button {
      border-radius: 0.5em;
      border: 1px solid #cccccc;
      width: 100%;
      padding: 0.5em 0em;
      background-color: #fff;
      color: @text-color;
      margin-top: 24px;
      img {
        padding-right: 0.5em;
        height: 25px;
        width: auto;
      }
    }
    .passEye {
      position: relative;
      margin-top: -30px;
      float: right;
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
}
.btn-google {
  width: 100%;
  box-shadow: none !important;
  margin-top: 24px;
  border: 1px solid #cccccc !important;
  border-radius: 0.5em !important;
}
