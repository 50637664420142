.main-container-N {
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
}

.content-apart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.main-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.notification-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.notification-item {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.notification-item.unread {
  background-color: #eaf4ff;
}

.notification-item.read {
  background-color: #f4f4f4;
}

.notification-header {
  display: flex;
  align-items: center;
}

.notify-img {
  margin-right: 10px;
}

.notification-info {
  display: flex;
  flex-direction: column;
}

.notification-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.notification-date {
  margin: 0;
  font-size: 14px;
  color: #888;
}

.notification-content {
  margin-top: 10px;
}

.form-wrapper {
  margin-top: 20px;
}

.global-modal .ant-modal-content {
  border-radius: 8px;
}

.global-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.global-modal .ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.global-modal .ant-btn-primary:hover {
  background-color: #40a9ff;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-checkbox-wrapper {
  margin-right: 10px;
}

input::placeholder {
  color: #888;
}
