#contact{
    width: 100%;
    height: 500px;
    background-color: white;
    border-radius: 2em;
    margin-top: 3rem;
    padding: 2rem;

 
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 90px;
}
}