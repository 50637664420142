.container {
  width: 100%;
  display: flex;
  align-self: center;
  margin: auto;
}

.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);
.make-container(@screen-xxl-min, @screen-xxl);
